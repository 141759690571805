import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';

import './Popper.less';

export enum Placements {
  Auto = 'auto',
  AutoStart = 'auto-start',
  AutoEnd = 'auto-end',
  Top = 'top',
  TopStart = 'top-start',
  TopEnd = 'top-end',
  Bottom = 'bottom',
  BottomStart = 'bottom-start',
  BottomEnd = 'bottom-end',
  Right = 'right',
  RightStart = 'right-start',
  RightEnd = 'right-end',
  Left = 'left',
  LeftStart = 'left-start',
  LeftEnd = 'left-end',
}

type Props = {
  arrowClasses?: string;
  hideContent?: () => void;
  hideOnOutsideClick?: boolean;
  hideOnResize?: boolean;
  hideOnScroll?: boolean;
  flipOptions?: object;
  offset?: number[];
  placement?: Placements;
  popperClasses?: string;
  showArrow?: boolean;
  showContent: boolean;
  triggerElement: (HTMLElement) => JSX.Element;
  strategy?: string;
};

const Popper: React.FC<Props> = (props) => {
  const {
    arrowClasses = 'w-2 h-2',
    children,
    hideContent = () => undefined,
    hideOnOutsideClick = true,
    hideOnResize = true,
    hideOnScroll = true,
    flipOptions = null,
    offset = [0, 8],
    placement = Placements.Auto,
    popperClasses = 'bg-white',
    showArrow = true,
    showContent = true,
    triggerElement,
    strategy,
  } = props;

  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  /**
   * Popper comes with a lot of simple and advanced customizations via
   * modifiers.
   *
   * See https://popper.js.org/docs/v2/modifiers
   */
  const formPopperModifiers = () => {
    const modifiers = [];

    if (showArrow) {
      modifiers.push({ name: 'arrow', options: { element: arrowElement } });
    }

    if (offset) {
      modifiers.push({ name: 'offset', options: { offset } });
    }

    if (flipOptions) {
      modifiers.push({ name: 'flip', options: flipOptions });
    }

    return modifiers;
  };

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    // @ts-expect-error PositioningStrategy
    strategy,
    modifiers: formPopperModifiers(),
  });

  const handleClick = (e) => {
    if (
      showContent &&
      !popperElement?.contains(e.target) &&
      !referenceElement?.contains(e.target)
    ) {
      hideContent();
    }
  };

  const handleMouseDown = (e) => {
    if (
      showContent &&
      (popperElement?.contains(e.target) ||
        referenceElement?.contains(e.target))
    ) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown);

    if (hideOnOutsideClick) {
      window.addEventListener('click', handleClick);
    }

    if (hideOnResize) {
      window.addEventListener('resize', hideContent);
    }

    if (hideOnScroll) {
      document.addEventListener('scroll', hideContent);
    }

    return () => {
      window.removeEventListener('mousedown', handleMouseDown);

      if (hideOnOutsideClick) {
        window.removeEventListener('click', handleClick);
      }

      if (hideOnResize) {
        window.removeEventListener('resize', hideContent);
      }

      if (hideOnScroll) {
        document.removeEventListener('scroll', hideContent);
      }
    };
  }, [popperElement]);

  return (
    <>
      {triggerElement(setReferenceElement)}

      {showContent && (
        <div
          className={`z-10 ${popperClasses}`}
          id="popper"
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {children}

          {showArrow && (
            <div
              className={`invisible shadow-popover z-20 ${arrowClasses}`}
              id="popper-arrow"
              ref={setArrowElement}
              style={styles.arrow}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Popper;
